<template>
    <b-list-group-item class="d-flex justify-content-between align-items-center" :variant="variant">
        <!-- style="display: -webkit-box;" -->
        <b-row v-if="notification.IsRead">
            <!-- <b-avatar variant="light" icon="bell" class="mx-1"></b-avatar> -->
            <!-- class="mx-1" -->
            <b-avatar variant="light" class="mr-3" style="display: block;">
                <img :src="'/images/notify_grey_new_32dp.png'" style="width: 29px; height: 29px;"/>
            </b-avatar>
            <div class="media-body">                
                {{notifictionText(notification.Text)}}
                <br />
                <small class="text-muted">{{$moment.utc(notification.CreatedAt).local().fromNow()}}</small>&nbsp;
                <small class="text-muted">{{$moment.utc(notification.CreatedAt).local().calendar()}}</small>
            </div>
        </b-row>
         <!-- style="display: -webkit-box;" -->
        <b-row v-else>  
        <!-- class="mx-1" -->            
            <b-avatar
                variant="light"                
                class="mr-3"
                style="display: block;"
            >
            <img :src="'/images/notify_grey_new_active_32dp.png'" style="width: 29px; height: 29px;"/>
            </b-avatar>      
            <div class="media-body">                
                 {{notifictionText(notification.Text)}}
                <br />
                <small class="text-muted">{{$moment.utc(notification.CreatedAt).local().fromNow()}}</small>&nbsp;
                <small class="text-muted">{{$moment.utc(notification.CreatedAt).local().calendar()}}</small>
            </div>
        </b-row>
    </b-list-group-item>
</template>
<script>
export default {
    name: "user-list-item",

    computed: {
        variant() {            
            return this.notification.IsRead ? "" : "zssecondary";
        }
    },
    props: ["notification"],
    created() {},
    methods: {
        notifictionText(text){
            if(text.includes("has sent you a digital key in Zesec!")){
                text = text.replace("has sent you a digital key in Zesec!",this.$t("notifications.digital"));
            }
            if(text.includes("has removed a digital key from you in Zesec!")){
                text = text.replace("has removed a digital key from you in Zesec!",this.$t("notifications.remove_digital"));
            }
            return text;
        }
    }
};

</script>

