<template>
    <div class="main-content">
        <div class="header">
            <div class="container-fluid">
                <!-- Body -->
                <div class="header-body">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="header-pretitle">
                                {{ $t("users.profile") }}
                            </h6>
                            <h1 class="header-title">
                                {{ $t("notifications.title") }}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-container fluid> 
          <b-row>
                <b-col cols="12">
                    <div class="card">                                              
                        <div class="card-header">
                            <h4 class="card-header-title"></h4>                             
                                 <div class="col-auto">
                                    <form>
                                            <Select2
                                                v-model="perNotificationPage"
                                                :options="perPageOptions"
                                                id="mySelect18"
                                                :settings="{
                                                minimumResultsForSearch: -1,
                                                containerCssClass:
                                                    'custom-select custom-select-sm form-control-flush',
                                                    dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                                                }"
                                                @change="notificationList"                           
                                            />
                                            </form>
                                        </div>
                                    </div>
                    <div class="card-header">  
                            <div class="col-auto">
                            <form>
                                <div class="input-group input-group-flush input-group-merge">
                                <!-- Input -->
                                <b-form-input
                                    :placeholder="$t('common.search')"
                                    v-model="filter"
                                    class="form-control form-control-prepended search"
                                    type="search"
                                ></b-form-input>

                                <!-- Prepend -->
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                    <span class="fe fe-search"></span>
                                    </div>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div> 
                                    <div class="card-body">
                                        <b-pagination
                                            v-model="currentNotificationPage"
                                            :total-rows="notificationList.length"
                                            :per-page="perNotificationPage"
                                            aria-controls="my-table"
                                            class="pb-2"
                                    ></b-pagination> 
                                        <zs-list-transition :showLoader="showLoader">
                                            <template v-slot:content>
                                                <!-- <notification-list-item
                                                    v-for="notification in notificationList"
                                                    :notification="notification"
                                                    v-bind:key="notification.Id"
                                                /> -->
                                                <notification-list-item
                                                    v-for="notification in paginatedNotificationList"
                                                    :notification="notification"
                                                    v-bind:key="notification.Id"
                                                />
                                            </template>
                                            <template v-slot:empty>
                                                <empty-list
                                                    :title="
                                                        $t('notifications.no_notifications')
                                                    "
                                                />
                                            </template>
                                        </zs-list-transition>
                                        <b-pagination
                                            v-model="currentNotificationPage"
                                            :total-rows="notificationList.length"
                                            :per-page="perNotificationPage"
                                            aria-controls="my-table"
                                            class="pb-2"
                                    ></b-pagination> 
                                    </div>
                    </div>                     
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import ListTransition from "@/components/common/ListTransition";
import NotificationListItem from "@/components/notifications/NotificationListItem";
import EmptyList from "@/components/common/EmptyList";
import Select2 from "@/components/common/Select2";

import { mapGetters } from "vuex";

import {
    GET_NOTIFICATIONS,
    READ_NOTIFICATIONS,
} from "@/store/types/notifications";

export default {
    data() {
        return {
            showLoader: true,
            filter: "",
            currentNotificationPage: 1,
            perNotificationPage: 20,      
            perPageOptions: [
                { id: 10, text: "10 " + this.$t("menu.per_page") },
                { id: 20, text: "20 " + this.$t("menu.per_page") },
                { id: 50, text: "50 " + this.$t("menu.per_page") },
            ],
        };
    },
    computed: {
        ...mapGetters(["getUserNotifications", "getUserNotificationsCount"]),
        notificationList() {
            return [...this.getUserNotifications]
            .filter((item)=>item.Text.toLowerCase().includes(this.filter.toLowerCase()))
            .sort(
                (i1, i2) => new Date(i2.CreatedAt) - new Date(i1.CreatedAt)
            );
        },
        paginatedNotificationList() {
            return this.notificationList.slice(
                (this.currentNotificationPage - 1) * this.perNotificationPage,
                this.currentNotificationPage * this.perNotificationPage
            );
        },
    },
    created() {
        this.$store
            .dispatch(GET_NOTIFICATIONS)
            .then(() => (this.showLoader = false));    
        window.addEventListener("onload", this.onLoad);    
    },
    methods: {
        onLoad(evt){
            if(document.getElementsByClassName('badge-zsred')){
                document.getElementsByClassName('badge-zsred').style.right = '6px!important';
            }
        },
    },
    components: {
        NotificationListItem,
        "zs-list-transition": ListTransition,
        "empty-list": EmptyList,
        Select2,
    },
    beforeDestroy() {
        if (this.getUserNotificationsCount) {
            this.$store.dispatch(READ_NOTIFICATIONS);
        }
    },
};
</script>
<style scoped>
.b-avatar-badge .badge-zsred {
    right: 8px!important;
    top: 35px!important;
}
</style>
